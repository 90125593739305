body {
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: 14px;
  line-height: 24px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: poppins, sans-serif !important;
}
p,
button {
  font-family: Open Sans, sans-serif !important;
}
button {
  padding: 1em;
  border-radius: 2em;
}
