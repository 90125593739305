.Counter-bg {
  background-color: #fff3f2 !important;
}
.counterIcon {
  font-size: 2.5em;
  font-family: "Simple-Line-Icons" !important;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
}
.num{
  display: block;
}
.countTXT {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #2e353e;
}
.CountUp {
  font-size: 24px;
  font-weight: 600;
}
.count-sec {
  border-bottom: 1px solid grey;
}
