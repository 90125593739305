.ToTop {
  position: fixed;
  right : .6em;
  bottom: 0em;
  z-index: 1;
  opacity: .8;
  border-radius: .2em .2em 0 0;
  cursor: pointer;
  background-color: #555454;
  color: #fff;
}
.ToTop:hover{
  color: #fc6257;
}