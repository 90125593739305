.Nav-bg {
  z-index: 10;
  border-bottom: 0.1px solid #999;
  background-color: #ff4618;
  opacity: 0.8 !important;
}
.logo {
  height: 3.8em;
  width: 4em;
}
.navItem {
  font-family: Open Sans, sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: white !important;
}
.navIteam:hover {
  opacity: 0.7 !important;
}
.navIteam:active,
.acttive,
.navIteam:visited {
  opacity: 0.7 !important;
}

.dark {
  background-color: rgba(36, 43, 50, 1);
}
Button.logIn {
  padding: 0.7em 3.7em;
  margin-top: 0.2em;
  margin: auto;
  border-radius: 3em;
  border: none;
  background-color: #ff4618;
  font-size: 12px;
}
Button.logIn:hover {
  background-color: #ff4618;
}
