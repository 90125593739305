.Home {
  /* background: url(./Home-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: #ff4618;
  padding-top: 7em;
  padding-bottom: 0 !important;
}
.check {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 9px;
}
.checkhd {
  font-weight: 600;
  font-size: 70px;
  font-family: Poppins, sans-serif;
  margin-left: -5px !important;
}
.checkp {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0 0 30px;
}
.partxt {
  font-size: 14px;
  text-align: left;
}
.acra {
  position: relative;
  bottom: 0px;
}
.carbg {
  background: url(./home-slider/smartphone.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50% 100%;
  height: 30em;
}
.slide-cont {
  width: 17em !important;
  margin: auto;
  margin-bottom: 0 !important;
}
.sldie {
  height: 22.8em;
  width: 17em;
  margin-top: 7.2em;
}
Button.getBTN {
  font-size: 12px;
  border-radius: 3em;
  padding: 1.1em 3em;
  background-color: #ff4618;
  border: none;
}
Button.getBTN:hover {
  background-color: #ff4618;
}
Button.keybtn:hover {
  background-color: #ff4618;
  border: 1px solid #ff4618;
}
Button.keybtn {
  font-size: 12px;
  background-color: transparent;
  border: 1px solid #df837d;
  border-radius: 3em;
  padding: 1.1em 3.8em;
}
.ontop {
  border-top: 2px solid #fff;
}
@media (max-width: 800px) {
  .Home {
    /* background: url(./Home-bg-phone.jpg); */
    /* background-size: cover; */
    background-color: #ff4618;
  }
  .checkhd {
    font-size: 68px;
  }
}
@media (max-width: 400px) {
  .sldie {
    margin-top: 6em;
  }
  .carbg {
    height: 24.5em;
  }
  .slide-cont {
    height: 24.5em !important;
    width: 16em !important;
  }
}
@media (max-width: 340px) {
  .sldie {
    margin-top: 4.6em;
  }
  .carbg {
    height: 22.5em;
  }
  .slide-cont {
    height: 22.5em !important;
    width: 14.6em !important;
  }
  button.getBTN,
  button.keybtn {
    font-size: 11px;
  }
}
